/* base styles */
body {
  background-image: url('/assets/back.png');
  text-align: center;
}

h1, h2 {
  padding: 20px 0;
  font-family: 'Nothing You Could Do', cursive;
}

h2 {
  font-size: 35px;
}

a {
  color: #fff1e1;
  text-decoration: underline;
}

a:hover {
  color: #fffefe;
}

.img-circle {
  border-radius: 50%;
}

img {
  max-width: 100%;
}

ul {
  list-style: none;
}

ul.styled {
  list-style: square;
  text-align: left;
}

.btn-info {
  background-color: #333;
  border-color: #fff1e1;
  text-decoration: none;
}

.btn-info:hover {
  background-color: #fff1e1;
  border-color: #eee;
  color: #333;
}

.main {
  margin: 20px auto;
  color: #fff1e1;
}

.container {
  max-width: 600px;
  margin-bottom: 20px;
}

footer {
  position: fixed;
  bottom: 5px;
  right: 3px;
  width: 150px;
  line-height: 1.5em;
  text-align: center;
}

/* date countup */
.countup{
  margin: 10px auto;
  padding-bottom: 20px;
  font-size: 40px;
}
