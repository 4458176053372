.gallery {
    height: 600px;
    position: relative;
    overflow: hidden;
}

.gallery__nav {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    cursor: pointer;
    display: block;
    z-index: 3;
}

.gallery__nav span {
    display: inline-block;
    font-size: 20px;
    position: absolute;
    bottom: 48%;
    left: 25%;
    pointer-events: none;
    background: rgba(164, 115, 98, 0.5);
    width: 32px;
    height: 32px;
    border-radius: 20px;
}

.gallery__nav:hover span {
    opacity: .2%;
}

.gallery__nav--next {
    left: auto;
    right: 0;
}

.gallery__nav--next span {
    left: auto;
    right: 25%;
}

.gallery img {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 0;
    min-height: 250px;
    max-height: 700px;
    max-width: 100%;
    -webkit-transform: translate3d(-50%,-50%,0) scaleX(1);
    transform: translate3d(-50%,-50%,0) scaleX(1);
}

.gallery::after {
    position: absolute;
    left: 0;
    content: attr(data-index);
    padding: 5px 0;
    font-size: 12px;
    bottom: 0;
    right: 0;
    background: rgba(164, 115, 98, 0.5);
}

.loading {
    z-index: 5;
    position: absolute;
    left: 50%;
    top: 50%;
    color: black;
    margin: -32px 0 0 -32px;
}

.lds-heart {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    transform: rotate(45deg);
    transform-origin: 32px 32px;
}
.lds-heart div {
    top: 23px;
    left: 19px;
    position: absolute;
    width: 26px;
    height: 26px;
    background: #fff;
    animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
    content: " ";
    position: absolute;
    display: block;
    width: 26px;
    height: 26px;
    background: #fff;
}
.lds-heart div:before {
    left: -17px;
    border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
    top: -17px;
    border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
    0% {
        transform: scale(0.95);
    }
    5% {
        transform: scale(1.1);
    }
    39% {
        transform: scale(0.85);
    }
    45% {
        transform: scale(1);
    }
    60% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(0.9);
    }
}
